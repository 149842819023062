#showAttendancesPage {
    display: none;
}

#showAttendancesDrawer {
    display: block;
}

#tagHeader {
    display: none;
}

#tagExtra {
    display: block;
}

#editEventButtonWeb {
    display: block;
}

#footerMobile {
    display: none;
}

#headerWeb {
    display: block;
}

#headerMobile {
    display: none;
}

.pending-event #tagExtra {
    opacity: 0.4;
}

.pending-event .collapse-panel-details-content {
    opacity: 0.4;
}

.events-collapse-container {
    width: 100%;
}

.events-collapse-container>.ant-space-item {
    width: 100%;
}

.collapse-panel {
    display: flex;
}

.collapse-panel-details-content {
    width: 70%;
}

.collapse-panel-buttons-content {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.buttons-panel-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.buttons-panel-button-container>.admin-button-container {
    display: flex;
}

.buttons-panel-button-container>.admin-button-container>#deleteEventButton {
    margin-left: 10px;
    background-color: #ff4d4f !important;
    border-color: #ff4d4f !important;
}

deleteEventButton .event-header-tag {
    float: right;
}

span.event-header-tag {
    height: 30px;
    font-size: 14px;
    line-height: 26px;
}

.main-content {
    margin-bottom: 10px;
}


@media only screen and (max-width : 480px) {
    #showAttendancesDrawer {
        display: none;
    }

    #showAttendancesPage {
        display: block;
    }

    #tagHeader {
        display: flex;
        flex-direction: column;
    }

    #tagExtra {
        display: none;
    }

    #editEventButtonWeb {
        display: none;
    }

    #footerMobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #headerWeb {
        display: none;
    }

    #headerMobile {
        display: flex;
        flex-direction: column;
        padding-right: 5px;
    }

    .collapse-panel {
        flex-direction: column;
    }

    .collapse-panel-buttons-content {
        width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

    .buttons-panel-button-container {
        width: 100%;
    }

    .events-collapse-container>.ant-space-item>.ant-collapse>.ant-collapse-item>.ant-collapse-header>span.ant-collapse-header-text {
        width: 100%;
    }
}