.header {
    display: none;
}

.form-helper {
    margin-bottom: 20px;
    font-size: 14px;
    display: block;
}

.drawer-content {
    height: calc(100vh - 370px);
    overflow-y: auto;
}

.footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid lightgray;
    padding-bottom: 80px;
    padding: 20px 30px;
    height: 240px;
    
}

.form-container {
    float: left;
}

@media only screen and (max-width : 480px) {
    .list-container {
        height: calc(100vh - 150px - 42px - 190px);
        overflow-y: auto;
    }

    .header {
        display: block;
        margin-bottom: 10px;
    }

    .form-container {
        display: flex;
        flex-direction: column;
    }

    .participate-button {
        margin-top:10px
    }

    .go-back-button-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .go-back-button-label {
        margin-left: 5px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 50px);
        border-top: 1px solid lightgray;
        padding-bottom: 80px;
        padding: 20px 30px 5px;
        height: 150px;
    
    }
    .form-helper {
        display: none;
    }
}