a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

header {
  background: #303030;
}

.navbar-light .navbar-brand {
  margin-left: 20px;
  font-family: Montserrat,Arial,sans-serif;
  font-weight: 600;
  color: white;
}

.navbar-light .navbar-brand:hover {
  color: #ff8000;
}

.navbar-light .navbar-brand:focus {
  color: white;
}

.notification-badge {
  color: white !important;
  cursor: pointer;
}

.notification-badge:hover {
  color: #ff8000 !important;
}