/* Provide sufficient contrast against white background */
body {
  overflow-y: hidden;
  max-height: 100vh;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ant-btn:active {
  color: #ff8000 !important;
  border-color: #ff8000 !important;
}

.ant-btn:hover {
  color: #ff8000 !important;
  border-color: #ff8000 !important;
}

.ant-btn:focus {
  border-color: #ff8000 !important;
}

.ant-btn:visited {
  color: #ff8000 !important;
  border-color: #ff8000 !important;
}

.ant-btn.ant-btn-primary {
  background-color: #ff8000 !important;
  border-color: #ff8000 !important;
}

.ant-notification-notice-success {
  background-color: #f6ffed !important;
  border: 1px solid #b7eb8f !important;
}

.ant-notification-notice-error {
  background-color: #fff2f0 !important;
  border: 1px solid #ffccc7 !important;
}

.ant-spin-dot-item {
  background-color: #ff8000 !important;
}

.ant-spin-text {
  color: #ff8000 !important;
}