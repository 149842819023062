#horizontalDateRange {
    display: none;
}
#verticalDateRange {
    display: contents;
}
.race-header {
    display: flex;
    justify-content: space-between;
}
.date-time-container {
    display: flex;
    margin-bottom: 50px;
}
.search-button {
    content: "Rechercher";
    margin-left: 30px;
}

.header-button > .anticon {
    vertical-align: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  
@media only screen and (max-width : 480px) {
    #verticalDateRange {
        display: none;
    }
    #horizontalDateRange {
        display: contents;
    }
    .race-header {
        flex-direction: column;
    }
    .date-time-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .search-button {
        margin-left: 0;
    }
}