.main-container {
  max-height: 100vh;
  overflow-y: 'hidden';
}
.content {
  display: flex;
}

.events-list-container {
  width: 100%;
  border-right: 1px solid lightgrey;
  padding-right: 20px;
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.event-card-container {
  margin-left: 20px;
}

.spin-container {
  display: flex;
  justify-content: center;
}
