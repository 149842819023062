.title {
   
    display: flex;
    align-items: center;
}
.title-label {
    text-transform: capitalize;
    margin-left: 10px;
}

.events-container {
    margin-bottom: 100px;
}